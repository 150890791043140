/* css for override bootstrap default styles*/
.h1,.h2,.h3,h1,h2,h3,p {
	margin-top: 0;
	margin-bottom: 0;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
	vertical-align: middle;
}

.btn span.glyphicon {
	margin-right: 8px;
}

.table>tbody>tr>td.text-center,
.table>tbody>tr>th.text-center,
.table>tfoot>tr>td.text-center,
.table>tfoot>tr>th.text-center,
.table>thead>tr>td.text-center,
.table>thead>tr>th.text-center {
	text-align: center;
}

.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>th {
	background-color: #f5f5f5;
}

a{
	color:#000;
	
}

a:focus, a:hover{
	text-decoration:none;
	color:#fe6b73;
}

label {
	margin-bottom : 0;
}