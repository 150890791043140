@charset "utf-8";

* { margin: 0; padding: 0;}
body { text-align: left; width: 100%; word-break: break-all; word-wrap: break-word; position: relative;}
li { list-style: none;}
img { border: none; max-width:100%;}
.clear { clear: both; height: 1px; overflow: hidden; width: 100%; visibility: hidden; display: block; z-index: -1;}

/* Hides from IE-mac \*/
* html .clearfix {  height: 1%;}
.clearfix {  display: block;}
/* End hide from IE-mac */

.bold { font-weight: bold;}
.w-max { width: 100%;}
.hidden { display: none;}
.disp-b { display: block;}
.m-auto { margin: 0 auto;}
.text-limit { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; -webkit-text-overflow: ellipsis; -o-text-overflow: ellipsis;}
.txt-red { color: #F44336 !important; -webkit-print-color-adjust: exact;}
img.native{max-width:100%;}

.val-t { vertical-align: top;}
.val-m { vertical-align: middle;}
.val-b { vertical-align: bottom;}

@import 'modules/_overrideBootstrap';

@media screen and (max-width: 767px) {
    .pc-only {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .sp-only {
        display: none;
    }
}
@media screen and (max-width: 400px) {
	$pixel_val: 2;
	@import 'modules/_layout_module';
}

@media screen and (min-width: 401px) and (max-width: 560px) {
	$pixel_val: 3;
	@import 'modules/_layout_module';
}

@media screen and (min-width: 560px) { 
	$pixel_val: 4;
	@import 'modules/_layout_module';
}